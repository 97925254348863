import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotificationService } from './common/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import * as signalR from '@microsoft/signalr';
import { ReportingResultService } from './components/reporting-result/services/reporting-result.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#004890"
      type="ball-spin"
      [fullScreen]="true"
      ><p style="color: white" (scroll)="(false)">Yükleniyor...</p></ngx-spinner
    > `,
  standalone: true,
  providers: [DatePipe],
  imports: [CommonModule, RouterModule, NgxSpinnerModule],
})
export class AppComponent implements OnInit {
  title = 'FonRiskDemoV1-FrontEnd';
  private hubConnection: signalR.HubConnection | undefined;
  private token: string | null = null;
  portfolioCode: string;
  isDisabled: boolean = false;
  constructor(
    private notificationService: NotificationService,
    private _toastr: ToastrService,private _reportService:ReportingResultService
  ) {}
  ngOnInit(): void {
    // this.notificationService.getNotifications(corporationId);
    // this.token = localStorage.getItem('accessToken');
    // var corporationId = localStorage.getItem('corporationId');
    // this._reportService.checkPending((res: any) => {});
    // this.startConnection();
    // this.getConnection()?.on('CheckData', (data) => {
    //   data.forEach((e: any) => {
    //     this.portfolioCode = e.portfolioCodeDate;
    //   });
    //   this.notificationService.getNotifications(corporationId);
    //   if (data.length > 0) {
    //     this.isDisabled = true;
    //   } else {
    //     if (this.portfolioCode) {
    //       const message = `${this.portfolioCode} adlı raporunuz hazır!`;
    //       this._toastr.info(`${this.portfolioCode} adlı raporunuz hazır!`);
    //       // this.notificationService
    //       //   .addNotification(message, corporationId)
    //       //   .subscribe(
    //       //     (response) => {
    //       //       this.notificationService.getNotifications(corporationId);
    //       //     },
    //       //     (error) => {
    //       //       console.error('Bildirim gönderilirken bir hata oluştu:', error);
    //       //     }
    //       //   );
    //     }
    //     this.notificationService.getNotifications(corporationId);
    //     this.isDisabled = false;
    //     if (!this.isDisabled) {
    //       this.stopConnection();
    //     }
    //   }
    // });
  }
  // public stopConnection(): void {
  //   if (this.hubConnection) {
  //     this.hubConnection.stop().catch((err) => console.error(err));
  //     this.hubConnection = undefined;
  //   }
  // }
  // public getConnection(): signalR.HubConnection | undefined {
  //   return this.hubConnection;
  // }
  // public startConnection(): void {
  //   if (!this.hubConnection) {
  //     this.hubConnection = new signalR.HubConnectionBuilder()
  //       .withUrl(environment.checkSignalrDevApiUrl, {
  //         accessTokenFactory: () => this.token || '',
  //         withCredentials: false,
  //       })
  //       .withAutomaticReconnect()
  //       .build();

  //     this.hubConnection.start().catch((err) => console.error(err));
  //   }
  // }
}
